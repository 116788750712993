import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GiftCards from "../../components/body/pages/bill-payments/gift-cards"

const GiftCardsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/gift-cards/"}
      title="Gift Cards | Easy and Secure | Kuda"
      description="Get access to a wide range of gift cards with Kuda. Buy and send gift cards securely and hassle-free. Join Kuda today!"
    />
    <GiftCards />
  </Layout>
)

export default GiftCardsPage
